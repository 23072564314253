.block {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 10px 30px 10px;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px 10px;
    width: 100%;
    min-height: 400px;
}

.title {
    font-size: 3rem;
    color: var(--secondary);
    margin-bottom: 1rem;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Titillium", sans-serif;
    width: 100%;
    text-align: left;
}

.label {
    display: none;
}

.inputText,
.inputTextArea {
    font-family: "Titillium", sans-serif;
    width: 100%;
    border: 1px solid var(--line);
    outline: none;
    padding: 0.7rem 1rem;
    background-color: var(--primary);
    border-radius: 10px;
    box-sizing: border-box;
    font-size: 1rem;
    color: var(--secondary);
}

.inputText::placeholder,
.inputTextArea::placeholder {
    color: var(--text);
}

.inputText:focus,
.inputTextArea:focus {
    border: 1px solid var(--secondary);
    outline: none;
}

.inputTextArea {
    resize: none;
    vertical-align: top;
}

.inputSubmit {
    background-color: var(--accent);
    color: var(--primary);
    border: 1px solid var(--accent);
    cursor: pointer;
    padding: 1rem 2rem;
    font-size: 1rem;
    border-radius: 10px;
    width: 100%;
    text-transform: uppercase;
    font-family: "Alata", sans-serif;
}

.fieldContainer {
    min-height: 75px;
    display: flex;
    flex-direction: column;
}

.fieldContainer:last-of-type {
    min-height: 98px;
}

.errorMessage {
    font-size: 0.8rem;
    color: var(--primary);
    padding: 0 1rem 2px 1rem;
    border-radius: 0 0 10px 10px;
    font-weight: bold;
    width: max-content;
    background-color: var(--error);
    margin-left: 10px;
    margin-bottom: 0.5rem;
}

.emoji {
    font-size: 5rem;
}

@media (min-width: 480px) {
    .container {
        max-width: 550px;
    }

    .title {
        font-size: 3rem;
    }

    .inputText:hover,
    .inputTextArea:hover {
        border: 1px solid var(--secondary);
        outline: none;
    }

    .inputSubmit {
        border: 1px solid var(--accent);
    }

    .inputSubmit:hover {
        border: 1px solid var(--success);
        background-color: var(--success);
        color: var(--primary);
    }
}
