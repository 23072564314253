.menu {
    width: 100%;
    position: absolute;
    top: 64px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 61.5px);
    background-color: var(--primary);
    gap: 1rem;
    padding: 1rem;
}

.link {
    font-size: 2rem;
    font-weight: bold;
    background-color: var(--primary);
    color: var(--secondary);
    transition: 2s all ease-in-out;
    border: 1px solid var(--line);
    border-radius: 10px;
    width: 100%;
    text-align: center;
    padding: 1rem 0;
}

@media (min-width: 400px) {
    .menu {
        top: 62px;
    }
}
