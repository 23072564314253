.block {
    width: 100%;
    margin-top: 78px;
    padding: 0 30px;
}

.sectionHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hugeText {
    font-size: 4rem;
    font-weight: bold;
    color: var(--secondary);
    text-transform: uppercase;
}

.paragraph {
    font-size: 1.1rem;
    text-align: center;
    width: 100%;
    max-width: 560px;
}

.underline {
    text-decoration-line: underline;
    text-decoration-thickness: 0.2rem;
    text-decoration-color: var(--accent);
}

.success {
    color: var(--success);
}

.accent {
    color: var(--accent);
}

@media (min-width: 920px) {
    .block {
        width: 100%;
        max-width: 1660px;
        display: flex;
        flex-direction: center;
    }

    .sectionHeader {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        align-self: flex-start;
    }

    .hugeText {
        font-size: 5rem;
        font-weight: bold;
        text-transform: uppercase;
        border-right: 7px solid var(--accent);
        padding-right: 2rem;
        margin-right: 2rem;
    }

    .paragraph {
        font-size: 1.3rem;
        text-align: left;
        width: 100%;
        max-width: 640px;
    }
}
