.block {
    width: 100%;
    display: flex;
    justify-content: center;
}

.workGridContainer {
    width: 100%;
}

.filterContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2rem 10px 0 10px;
}

.filters {
    list-style-type: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0;
    padding: 0;
}

.filter {
    color: var(--text);
    text-transform: capitalize;
    border: 1px solid var(--line);
    border-radius: 10px;
    padding: 10px;
    font-size: 1.2rem;
    background-color: transparent;
}

.selected {
    color: #fff;
    border: 1px solid var(--accent);
}

.cardGrid {
    padding: 1rem 20px;
}

@media (min-width: 720px) {
    .workGrid {
        grid-template-columns: 1fr 1fr;
    }

    .selected {
        font-weight: bold;
    }
}

@media (min-width: 400px) {
    .filter:hover {
        cursor: pointer;
        color: var(--secondary);
        border: 1px solid var(--success);
    }
}

@media (min-width: 920px) {
    .sectionHeader {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .workGridContainer {
        max-width: 1660px;
    }

    .hugeText {
        font-size: 5rem;
        font-weight: bold;
        text-transform: uppercase;
        border-right: 7px solid var(--accent);
        padding-right: 2rem;
        margin-right: 2rem;
    }

    .paragraph {
        text-align: left;
    }

    .filterContainer {
        justify-content: flex-start;
        padding-left: 16px;
    }

    .cardGrid {
        padding: 0;
        margin: 1rem 0 0 0;
    }
}

.details {
    width: 100%;
    border: 1px solid white;
    padding: 1rem;
    border-radius: 10px;
    background-color: var(--secondary);
    color: var(--primary);
}

.title {
    color: var(--accent);
}
