.block {
    width: 100%;
    border-top: 1px solid var(--line);
    display: flex;
    justify-content: center;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    color: var(--text);
    padding: 1rem;
    height: 130px;
}

.socialContainer {
    display: flex;
    align-items: center;
}

.social {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.social svg {
    fill: var(--text);
}

.brand {
    font-family: "Alata", sans-serif;
    text-transform: uppercase;
    border-right: 3px solid var(--accent);
    padding-right: 12px;
    margin-right: 10px;
}

.copyright {
    font-size: 1.1rem;
}

@media (min-width: 400px) {
    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: max-content;
        max-width: 1660px;
    }

    .social svg:hover {
        fill: var(--success);
        cursor: pointer;
    }

    .social {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.2rem;
    }
}
