.block {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    background-color: var(--primary50);
    color: var(--secondary);
    border: none;
}

.modalContainer {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 30px;
    height: 100%;
    width: 100%;
}

.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}

.brand {
    font-size: 1rem;
    text-align: center;
    border-bottom: 5px solid var(--accent);
    padding-bottom: 0.4rem;
    margin-bottom: 0.2rem;
    text-transform: uppercase;
}

.title {
    font-size: 2rem;
    text-align: center;
    line-height: 1.2;
    margin-bottom: -1.2rem;
}

.description {
    border-radius: 10px;
    text-align: justify;
    width: 100%;
    color: var(--text);
}

.clickables {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

button.button,
a.button {
    font-family: "Alata", sans-serif;
    background-color: transparent;
    color: var(--secondary);
    border: 1px solid var(--secondary);
    font-size: 1.5rem;
    border-radius: 10px;
    padding: 1rem 0;
    width: 100%;
    text-align: center;
}

a.button {
    color: var(--primary);
    border: 1px solid var(--accent);
    background-color: var(--accent);
}

a.button:hover {
    border: 1px solid var(--success);
    background-color: var(--success);
    color: var(--primary);
}

button.button:hover {
    cursor: pointer;
    border: 1px solid var(--success);
}

.offline {
    color: var(--error);
    font-style: italic;
}

@media (min-width: 540px) {
    .modalContainer {
        background-color: var(--primary50);
        max-width: 500px;
        box-shadow: 0px 0px 1000px var(--accent);
        border-radius: 10px;
        height: max-content;
    }
    .clickables {
        flex-direction: row;
        gap: 1rem;
        width: 100%;
    }
    button.button,
    a.button {
        font-size: 1.2rem;
        padding: 0.7rem;
    }
}
