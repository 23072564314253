.block {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    border-bottom: 1px solid var(--line);
    background-color: var(--primary);
}

.container {
    width: 100%;
    padding: 1rem 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logoWrapper {
    flex-grow: 1;
}

.logo {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--secondary);
    width: max-content;
}

.nav {
    display: flex;
    justify-content: center;
    align-items: center;
}

.link {
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text);
    background: none;
    border: none;
    text-transform: capitalize;
}

.hamburger {
    stroke: var(--secondary);
}

.nav {
    display: none;
}

@media (min-width: 400px) {
    .hamburger:hover {
        cursor: pointer;
    }
}

@media (min-width: 550px) {
    .container {
        max-width: 1660px;
    }

    .logo:hover {
        cursor: pointer;
    }

    .hamburger {
        display: none;
    }

    .nav {
        display: block;
    }

    .link:hover {
        cursor: pointer;
        color: var(--secondary);
        text-decoration-line: underline;
        text-decoration-thickness: 0.1rem;
        text-decoration-color: var(--success);
    }
}
