.theme {
    --primary: #0d0d0d;
    --primary50: #0d0d0dee;
    --secondary: #f2f2f2;
    --accent: #389cd6;
    --line: #ffffff33;

    --text: #cfd2d3;
    --shadow: #111;

    --error: #ff6b6b;
    --warning: #ff8800;
    --success: #16e351;
}

.noScroll {
    overflow: hidden;
}

@font-face {
    font-family: "Alata";
    src: local("Alata"), url("./assets/fonts/Alata-Regular.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Titillium";
    src: local("Titillium"), url("./assets/fonts/TitilliumWeb-Regular.ttf") format("truetype");
    font-display: swap;
}

* {
    margin: 0;
    box-sizing: border-box;
}

html {
    font-size: 15px;
    scroll-behavior: smooth;
}

body {
    color: white;
    margin: 0;
    font-family: "Titillium", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Alata", sans-serif;
}

a {
    text-decoration: none;
    color: var(--light);
}

a,
p,
button {
    font-family: "Titillium", sans-serif;
}

img,
video {
    aspect-ratio: attr(width) / attr(height);
}
