.container {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: var(--primary);
    flex-direction: column;
    align-items: center;
}

.anchor {
    visibility: hidden;
}

.projects {
    position: relative;
    top: -50px;
}
