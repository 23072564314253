.card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 10px;
    border: 1px solid var(--line);
    padding: 1rem;
    margin: 10px 0;
    background-color: transparent;
    text-align: left;
    font-size: 1rem;
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    font-size: 1.5rem;
    width: 100%;
}

.cardStatus {
    font-size: 1rem;
    font-family: "Alata", sans-serif;
    text-transform: capitalize;
}

.live {
    color: var(--success);
    padding: 0.1rem 0.5rem 0.2rem 0.5rem;
    border-radius: 5px;
}

.offline {
    color: var(--error);
    padding: 0.1rem 0.5rem 0.2rem 0.5rem;
    border-radius: 5px;
}

.demo {
    color: var(--warning);
    padding: 0.1rem 0.5rem 0.2rem 0.5rem;
    border-radius: 5px;
}

.cardBrand,
.cardTitle {
    color: var(--secondary);
    margin: 0;
    padding: 0;
    font-size: 1rem;
}

.cardTitle {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
}

.cardThumbnail {
    max-width: 100%;
    border-radius: 5px;
}

.cardSkills {
    color: var(--text);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    flex-grow: 1;
    text-transform: capitalize;
}

@media (min-width: 400px) {
    .card:hover {
        cursor: pointer;
        box-shadow: 0px 0px 40px var(--shadow);
        border: 1px solid var(--success);
    }
}

@media (min-width: 720px) {
    .card {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border-radius: 10px;
        border: 1px solid var(--line);
        padding: 1rem;
        margin: 1rem;
    }
}
